(function() {
    // View Venue Location popup
    $("a.workshop-view-location").click(function(e) {
        var leftOffset, topOffset;
        e.preventDefault();
        var dialogWidth = 800;
        var dialogHeight = 600;
        var screenLeft = 0;
        var screenTop = 0;
        var windowHeight = $(window).height();
        var windowWidth = $(window).width();
        if (window.screenLeft) {
            screenLeft = window.screenLeft;
            screenTop = window.screenTop;
        } else if (window.screenX) {
            screenLeft = window.screenX;
            screenTop = window.screenY;
        }
        leftOffset = screenLeft + (windowWidth - dialogWidth) / 2;
        topOffset = screenTop + (windowHeight - dialogHeight) / 2;
        console.log(this);
        return window.open(
            $(this).attr("href"),
            "_blank",
            "height=" +
                dialogHeight +
                ", width=" +
                dialogWidth +
                ", top=" +
                topOffset +
                ", left=" +
                leftOffset
        );
    });
})();
