// package.json
require('jquery-ujs')
require('bootstrap')
require('trix')
require('@rails/actiontext')
require('waypoints/lib/jquery.waypoints')

// Turbo
import '@hotwired/turbo-rails'

// Stimulus
import '../src/application/controllers'

// Vendored plugins
require('waypoints.sticky')

// shoelace tabs
import '@shoelace-style/shoelace/dist/themes/base.css';
import { SlTabGroup, SlTab, SlTabPanel } from '@shoelace-style/shoelace';

// components js
import '../src/application/view_components'

// custom js
require('src/application/addressable_tabs')
require('src/application/app')
require('src/application/book')
require('src/application/colleague')
require('src/application/file_drop')
require('src/application/forums')
require('src/application/payment')
require('src/application/payment_options')
require('src/application/pricing')
require('src/application/profile')
require('src/application/promo_code')
require('src/application/registration')
require('src/application/rich_text')
require('src/application/sections/mma')
require('src/application/expando')
require('src/application/stripe')
require('src/application/subscribe')
require('src/application/testimonials')
require('src/application/workshop')
require('src/application/floating_nav')
