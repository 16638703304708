import { Controller } from '@hotwired/stimulus';
// FIXME: this doesn't work as the version from /types/ gets chosen instead of /esm/
// import TomSelect from 'tom-select';
import TomSelect from 'tom-select/dist/esm/tom-select';
import TomSelect_caret_position from 'tom-select/dist/esm/plugins/caret_position/plugin';
import TomSelect_input_autogrow from 'tom-select/dist/esm/plugins/input_autogrow/plugin';
import TomSelect_remove_button from 'tom-select/dist/esm/plugins/remove_button/plugin';

TomSelect.define('caret_position', TomSelect_caret_position);
TomSelect.define('input_autogrow', TomSelect_input_autogrow);
TomSelect.define('remove_button', TomSelect_remove_button);

const defaults = {
  plugins: {
    'caret_position': {},
    'input_autogrow': {},
    'remove_button': {
      label: '<span class="ts-remove-icon-wrapper"><svg xmlns="http://www.w3.org/2000/svg" class="ts-remove-icon" width="31" height="32" viewBox="0 0 31 32"><path d="m14.817 17.59-4.477 4.59-1.38-1.38 4.477-4.59-4.477-4.59 1.38-1.38 4.477 4.59 4.477-4.59 1.38 1.38-4.477 4.59 4.477 4.59-1.38 1.38-4.477-4.59z"/></svg></span>'
    }
  },
  render: {
		item: function(data, escape) {
      console.log(data);
			return '<div class="ComboboxPill"><span class="ComboboxPill-label">' + escape(data.text) + '</span></div>';
		}
	}
};

export default class extends Controller {
  static values = { options: Object };

  connect() {
    this.options = this.optionsValue;
    this.initTomSelect();
  }

  disconnect() {
    if (this.select) {
      this.select.destroy();
    }
  }

  initTomSelect() {
    if (this.element) {
      const config = { ...defaults, ...this.options };
      this.select = new TomSelect(this.element, config);
    }
  }
}
