document.addEventListener('turbo:load', function() {
  Array.prototype.move = function (old_index, new_index) {
      if (new_index >= this.length) {
          var k = new_index - this.length;
          while ((k--) + 1) {
              this.push(undefined);
          }
      }
      this.splice(new_index, 0, this.splice(old_index, 1)[0]);
      return this; // for testing purposes
  };

  var print_triggers = $('.js-print-trigger');
  if (window.print) {
    print_triggers.click(function(event) {
      window.print();
      event.preventDefault();
    });
  } else {
    print_triggers.remove();
  }
  if ($('.widget-sticky').length > 0) {
    var sticky = new Waypoint.Sticky({
      element: $('.widget-sticky')[0]
    });
  }

  $('.share-facebook').on('click', function() {
      FB.ui(
       {
        method: 'share',
        href: 'https://developers.facebook.com/docs/'
      }, function(response){});
  });

});
