import { Controller } from "@hotwired/stimulus"

// FIXME: refactor a generic tab component that has an accompanying generic controller
export default class extends Controller {
  static classes = [ 'active' ];

  toggle(e) {
    const activeClass = this.activeClass
    // swap active tabs
    this.element.querySelector('.CategoryListDesktop-item a.' + activeClass).classList
      .remove(activeClass)
    e.target.closest('a').classList.add(activeClass)
    // swap active panels
    this.element.querySelector('.ToolbeltSelectorCategory.' + activeClass).classList.remove(activeClass)
    this.element.querySelector('.ToolbeltSelectorCategory.' + e.target.closest('a').dataset.category)
      .classList.add(activeClass)
  }
}
