document.addEventListener('turbo:load', function() {
    const $subscribe = $('#subscribe');
    $subscribe.on('ajax:success', function(event, data, status, xhr) {
      // console.log(xhr.responseText);
      $('#new_subscribe_form').replaceWith(xhr.responseText);
      // Send event to Google Analytics
      dataLayer.push({
        'event': 'newsletter_step1',
      });
    })
    $subscribe.on('ajax:error', function(event, xhr, status, error) {
      // console.log(xhr.responseText);
      $('#subscribe-blurb').hide();
      $('#new_subscribe_form').replaceWith(xhr.responseText);
    })
});
