document.addEventListener('turbo:load', function() {
  $("a.facebook-share").click(function(e) {
    return FB.ui({
      method: 'feed',
      link: document.URL,
      name: $(".slogan .name").text(),
      caption: $(".heading h2").text(),
      description: $(".post p").text()
    });
  });
  $("a.view-location").click(function(e) {
    var dialogHeight, dialogWidth, leftOffset, screenLeft, screenTop, topOffset, windowHeight, windowWidth;
    e.preventDefault();
    dialogWidth = 800;
    dialogHeight = 600;
    screenLeft = 0;
    screenTop = 0;
    if (window.screenLeft) {
      screenLeft = window.screenLeft;
      screenTop = window.screenTop;
    } else if (window.screenX) {
      screenLeft = window.screenX;
      screenTop = window.screenY;
    }
    windowWidth = $(window).width();
    leftOffset = screenLeft + (windowWidth - dialogWidth) / 2;
    windowHeight = $(window).height();
    topOffset = screenTop + (windowHeight - dialogHeight) / 2;
    return window.open($(this).attr('href'), "_blank", "height=" + dialogHeight + ", width=" + dialogWidth + ", top=" + topOffset + ", left=" + leftOffset);
  });
});
