document.addEventListener('turbo:load', function() {
  $("select[name='user[address_country]']").on('change', function() {
    var countryCode = $(this).find("option:selected").val();

    var $element = $("select[name='user[address_state]']");
    $element.empty();

    $.getJSON('/subregions?country=' + countryCode, function(result) {
      result = result || [];
      $.each(result, function(_key, value) {
        $element.append($("<option></option>").attr("value", value.code).text(value.name));
      });

      var $label = $("label[for='user_address_state']");
      if (countryCode == 'CA') {
        $label.text('Province');
      } else if (countryCode == 'US') {
        $label.text('State');
      } else {
        $label.text('Region');
      }
    })
  });
})
