window.displayAttendeeForm = function(
    eventSection,
    attendeeForm,
    formContainer
) {
    var newButton;
    newButton = eventSection.find('.AddAttendee-link');
    if (!formContainer) {
        formContainer = eventSection.find('.AddAttendee-form');
    }
    newButton.hide();
    formContainer
        .hide()
        .empty()
        .append(attendeeForm)
        .fadeIn();
    return attendeeForm.find(".cancel").on("click", function(e) {
        e.preventDefault();
        newButton.show();
        return formContainer.hide(50, function() {
            return $(this).empty();
        });
    });
};

window.removeAlertContainer = function() {
    var alertErrorContainer;
    alertErrorContainer = $(".alert-error");
    if (!alertErrorContainer.find("div").length) {
        return alertErrorContainer.remove();
    }
};

addLatestOrgDataToForm = function(attendeeForm) {
    var city,
        fieldName,
        fieldVal,
        findEndComma,
        orgData,
        previousAttendeeBox,
        value;
    previousAttendeeBox = $(".contact-box").last();
    if (!(previousAttendeeBox.length > 0)) {
        return;
    }
    fieldVal = function(sel) {
        return previousAttendeeBox.find(sel).html();
    };
    findEndComma = /(,)$/;
    city = fieldVal(".city").replace(findEndComma, "");
    orgData = {
        organization_name: fieldVal(".organization-name"),
        address: fieldVal(".address"),
        city: city,
        state: fieldVal(".state"),
        postal_code: fieldVal(".postal-code"),
        country: fieldVal(".country")
    };
    for (fieldName in orgData) {
      value = orgData[fieldName];
      attendeeForm.find("#attendee_" + fieldName + ", #contact_person_" + fieldName)[0].value = value
    }
};

document.addEventListener('turbo:load', function() {
    $("input[name='org_type']").on("change", function() {
        var ngo, registration_id;
        registration_id = $(this).data("registration-id");
        ngo = $(this).val();
        return $.ajax({
            type: "POST",
            url: "/registrations/" + registration_id + "/ngo",
            dataType: "script",
            data: {
                _method: "PUT",
                ngo: ngo
            }
        });
    });
    $(document).on("click", "a#submit-registration", function(e) {
        e.preventDefault();
        return $("form.edit_registration").trigger("submit");
    });
    $(".service-tab a").click(function(e) {
        $(".service-tab").removeClass("active");
        return $(this)
            .parent()
            .parent()
            .addClass("active");
    });
    $(document).on("change", "#registration_contact_email", function(e) {
        var alertError;
        alertError = $(".alert-error .contact");
        $("#contact-error-wrapper").empty();
        if (alertError.length) {
            if (
                $(this)
                    .find(":selected")
                    .val() !== ""
            ) {
                alertError.remove();
                return window.removeAlertContainer();
            }
        }
    });
    $(document).on("change", ".copy-org-details", function(e) {
        var attendeeForm, copyCheckBox;
        copyCheckBox = $(this);
        if (copyCheckBox.is(":checked")) {
            attendeeForm = copyCheckBox.parent().parent();
            addLatestOrgDataToForm(attendeeForm);
        }
    });
    $(document).on(
        "ajax:success",
        ".add-coaching-item-button, .remove-coaching-item-link",
        function(event, orderSummaryHtml) {
            return $(".order-summary-container .order-summary .wrap")
                .first()
                .replaceWith(orderSummaryHtml);
        }
    );
});
