document.addEventListener('turbo:load', function() {
  var autoRotate, four_seconds, rotateTestimonials, six_seconds, twenty_seconds;

  autoRotate = function() {
    rotateTestimonials();
    return rotateCarousel();
  };

  rotateTestimonials = function() {
    var currentItem, nextItem;
    currentItem = $('.testimonial-quotes-rotator .higher-quote.active');
    nextItem = currentItem.next(".higher-quote");

    if (nextItem.length < 1) {
      nextItem = $(".testimonial-quotes-rotator .higher-quote").first();
    }

    return currentItem.fadeOut(1000, function() {
      currentItem.removeClass("active");
      return nextItem.fadeIn(1000).addClass("active");
    });
  };

  twenty_seconds = 20000;
  four_seconds = 4000;
  six_seconds = 6000;

  return window.autoRotateInterval = setInterval(rotateTestimonials, six_seconds);
});
