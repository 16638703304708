import { application } from './application'
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers'

const context = require.context('.', true, /_controller\.js$/)
application.load(definitionsFromContext(context))

// FIXME: recursion not working as expected
// fails:
// const componentContext = require.context('../../../../components', true, /_controller\.js$/)
// works, need to provide full path, importing one directory at a time:
const selectorContext = require.context('../../../../components/toolbelt/selector', true, /_controller\.js$/)
application.load(definitionsFromContext(selectorContext))
const selectedContext = require.context('../../../../components/toolbelt/selector/selected', true, /_controller\.js$/)
application.load(definitionsFromContext(selectedContext))
