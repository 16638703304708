document.addEventListener('turbo:load', function() {
  const dropArea = document.querySelector('[data-file-drop]'),
        img = document.createElement('img'),
        closeX = document.createElement('span'),
        filename = document.createElement('span');
  var fileInput;
  closeX.classList.add('image-closer', 'void');
  filename.classList.add('filename')

  if (dropArea != null) {
    fileInput = dropArea.querySelector('input');
    img.src = '';
    dropArea.appendChild(img);
    dropArea.appendChild(closeX);
    dropArea.appendChild(filename);

    ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
      dropArea.addEventListener(eventName, preventDefaults, false)
    });

    ['dragenter', 'dragover'].forEach(eventName => {
      dropArea.addEventListener(eventName, highlight, false)
    });

    ['dragleave', 'drop'].forEach(eventName => {
      dropArea.addEventListener(eventName, unhighlight, false)
    });

    dropArea.addEventListener('drop', handleDrop, false);

    fileInput.addEventListener("change", handleBrowse, false)
  }

  function preventDefaults (e) {
    e.preventDefault();
    e.stopPropagation();
  }

  function highlight(e) {
    dropArea.classList.add('highlight')
  }

  function unhighlight(e) {
    dropArea.classList.remove('highlight')
  }

  function handleDrop(e) {
    let dt = e.dataTransfer
    handleFiles(dt.files)
  }

  function handleBrowse(e) {
    handleFiles(this.files)
  }

  function handleFiles(files) {
    let file = files[0]
    revalidate()

    if (valid(file)) {
      fileInput.files = files
      previewFile(file)
      showFilename(file)
    } else {
      reset()
      invalidate()
    }
  }

  function previewFile(file) {
    if (previewable(file)) {
      let reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onloadend = function() {
        img.src = reader.result
      }
      enableImageCloser()
    }
  }

  function showFilename(file) {
    filename.textContent = file.name
  }

  function valid(file) {
    let valid_types = fileInput.getAttribute('accept').split(", ");
    return valid_types.includes(file.type);
  }

  function previewable(file) {
    let previewable_types = ['image/png', 'image/jpg', 'image/jpeg'];
    return previewable_types.includes(file.type);
  }

  function enableImageCloser() {
    closeX.classList.remove('void')
    closeX.addEventListener('click', reset, false)
    img.addEventListener('click', reset, false)
  }

  function reset(e = null) {
    img.src = ''
    closeX.classList.add('void')
    fileInput.value = ''
    filename.textContent = ''
  }

  function revalidate() {
    dropArea.classList.remove('invalid')
    dropArea.classList.add('valid')
  }

  function invalidate() {
    dropArea.classList.add('invalid')
    dropArea.classList.remove('valid')
  }
})
