document.addEventListener('turbo:load', function() {
  let expandoBtn = document.querySelector('.js-expando');

  if (!expandoBtn) return;

  expandoBtn.addEventListener('click', event => {
    const expando = expandoBtn.closest('.Expando')
    const isOpen = expando.classList.toggle('is-open')

    // HACK for Safari, requires offsetHeight calculation to see changes to height and reflow
    const expandoContent = expando.querySelector('.Expando-content')
    expandoContent.offsetHeight

    expandoBtn.textContent = isOpen ? 'Show Less' : 'Show More'
  });
});

