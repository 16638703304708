document.addEventListener('turbo:load', function() {
  let tabs = document.querySelector('.js-tabs');

  if (tabs) {
    if (window.location.hash) {
      let panel = window.location.hash.substring(1);
      tabs.show(panel);
    }

    tabs.addEventListener('sl-tab-show', event => {
      let panel = event.detail.name;
      history.pushState(null, panel, '#' + panel);
    });
  }
});
