document.addEventListener('turbo:load', function() {
  function setParam(uri, key, val) {
    return uri
      .replace(new RegExp("([?&]"+key+"(?=[=&#]|$)[^#&]*|(?=#|$))"), "&"+key+"="+encodeURIComponent(val))
      .replace(/^([^?&]+)&/, "$1?");
  }

  $('#promo_code_link').on('click', function(e) {
    e.preventDefault();
    $('#promo_code_input').slideToggle();
  });

  $('#promo_code_apply').on('click', function(e) {
    document.location.assign(setParam(document.location.href, 'form[promo_code]', $('#form_promo_code').val()));
  });

  $('#form_promo_code').on('keypress', function(e) {
    if (e.which == 13) {
      e.preventDefault();
      $('#promo_code_apply').trigger('click');
    }
  });
});
