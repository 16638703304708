document.addEventListener('turbo:load', function() {
  let stripeInit = function(cardElement) {
    let stripePublishableKey = cardElement.dataset.stripePK;
    let stripe = Stripe(stripePublishableKey);
    let elements = stripe.elements();

    const style = {
      base: {
        iconColor: '#635e53',
        backgroundColor: '#fefcf6',
        color: '#635e53',
        fontWeight: 500,
        fontFamily: '"PFCentroSansPro", Helvetica, Arial, sans-serif',
        fontSize: '16px',
        fontSmoothing: 'antialiased',
        ':-webkit-autofill': {
          color: '#fce883',
        },
        '::placeholder': {
          color: '#9f998d',
        },
      },
      invalid: {
        iconColor: '#c62828',
        color: '#c62828',
      },
    };

    let card = elements.create('card', { hidePostalCode: true, style: style });
    card.mount('#card-element');

    card.on('change', function (event) {
      displayError(event);
    });
    card.on('focus', (event) => {
      document.getElementById('card-element').classList.add('is-focused')
    });
    card.on('blur', (event) => {
      document.getElementById('card-element').classList.remove('is-focused')
    });

    function reEnableSubmit() {
      let submit = document.getElementById('payment-form-submit');
      submit.disabled = false;
      submit.value = 'Purchase';
    }

    function displayError(event) {
      // changeLoadingStatePrices(false);
      let displayError = document.getElementById('card-element-errors');
      let cardElement = document.getElementById('card-element');
      if (event.error) {
        displayError.textContent = event.error.message;
        cardElement.classList.add('CreditCard-fieldWithErrors')
      } else {
        displayError.textContent = '';
        cardElement.classList.remove('CreditCard-fieldWithErrors')
        reEnableSubmit();
      }
    }

    function stripeTokenHandler(token) {
      // Insert the token ID into the form so it gets submitted to the server
      let form = document.getElementById('payment_form');
      let hiddenInput = document.createElement('input');
      hiddenInput.setAttribute('type', 'hidden');
      hiddenInput.setAttribute('name', 'form[stripe_token]');
      hiddenInput.setAttribute('value', token.id);
      form.appendChild(hiddenInput);

      // Submit the form
      form.submit();
    }

    function createToken() {
      stripe.createToken(card).then(function(result) {
        if (result.error) {
          // Inform the user if there was an error
          let displayError = document.getElementById('card-element-errors');
          displayError.textContent = result.error.message;
        } else {
          // Send the token to your server
          stripeTokenHandler(result.token);
        }
      });
    };

    // Create a token when the form is submitted.
    var form = document.getElementById('payment_form');
    form.addEventListener('submit', function(e) {
      e.preventDefault();
      createToken();
    });
  }

  let cardElement = document.getElementById('card-element');
  if (cardElement) {
    stripeInit(cardElement);
  }
})
