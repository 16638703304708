document.addEventListener('turbo:load', function() {
  var h = document.getElementById("navBar");
  if (h) {
    var stuck = false;
    var stickPoint = getDistance();

    function getDistance() {
      var topDist = h.offsetTop;
      return topDist;
    }

    window.onscroll = function(e) {
      var distance = getDistance() - window.pageYOffset;
      var offset = window.pageYOffset;
      if ( (distance <= 0) && !stuck) {
        h.style.position = 'sticky';
        h.style.top = '0px';
        stuck = true;
      } else if (stuck && (offset <= stickPoint)){
        h.style.position = 'relative';
        h.style.top = '-50px';
        stuck = false;
      }
    }
  }
});
