import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['selectedTopics', 'topicPillTemplate', 'noTopicsTemplate']

  connect() {
    this.renderTopicList()
  }

  deselectTopic(event) {
    const termId = event.target.closest('a').dataset.termId
    const checkbox = document.getElementById(`checkbox-${termId}`)
    checkbox.checked = false
    this.renderTopicList()
  }

  renderTopicList() {
    const buildPill = checkbox => {
      const topicPill = this.topicPillTemplateTarget.content.firstElementChild.cloneNode(true)
      topicPill.querySelector('.TopicPill-label').textContent = checkbox.dataset.label
      const removeLink = topicPill.querySelector('a')
      removeLink.dataset.termId = checkbox.value
      return topicPill
    }

    const checkboxes = this.element.querySelectorAll('input[type=checkbox]')
    const topicListItems = Array.from(checkboxes).filter(cb => cb.checked).map(cb => buildPill(cb))
    if (topicListItems.length == 0) {
      topicListItems.push(this.noTopicsTemplateTarget.content.firstElementChild.cloneNode(true))
    }
    this.selectedTopicsTarget.replaceChildren(...topicListItems)
  }
}
