document.addEventListener('turbo:load', function() {
  let tabs = document.querySelector('.js-payment-options');
  let submitButton = document.getElementById('submit-payment');

  function changeSubmitButtonText(panel) {
    if (panel === 'cheque') {
      submitButton.textContent = 'Send an Invoice'
    } else {
      submitButton.textContent = 'Send Payment'
    }
  }

  if (tabs) {
    if (window.location.hash) {
      let panel = window.location.hash.substring(1);
      changeSubmitButtonText(panel);
    }

    tabs.addEventListener('sl-tab-show', event => {
      let panel = event.detail.name;
      changeSubmitButtonText(panel);
    });
  }
});
