document.addEventListener('turbo:load', function() {
  const paymentForm = document.getElementById('payment-form');
  if (typeof Stripe !== 'undefined' && paymentForm) {
    const invoiceForm = document.getElementById('invoice-form');
    const submitButton = document.getElementById('submit-payment');
    const stripe = Stripe(paymentForm.dataset.stripePK);
    const appearance = {
      theme: 'stripe',
      variables: {
      //   colorPrimary: '#0570de',
      //   colorBackground: '#ffffff',
        colorText: '#635e53',
        colorTextPlaceholder: '#c3bdb0',
        colorDanger: '#9e2020',
        fontFamily: 'local(PFCentroSansPro), PF, Helvetica, Arial, sans-serif',
      //   spacingUnit: '2px',
      //   borderRadius: '4px',
      }
    };
    const options = {
      clientSecret: paymentForm.dataset.secret,
      appearance: appearance
    };

    // Pass the appearance object to the Elements instance
    const elements = stripe.elements(options);

    const paymentElement = elements.create('payment');
    paymentElement.mount('#payment-element');

    function showMessage(messageText) {
      const messageContainer = document.getElementById("payment-message");

      messageContainer.classList.remove("u-hidden");
      messageContainer.textContent = messageText;

      setTimeout(function () {
        messageContainer.classList.add("u-hidden");
        messageText.textContent = "";
      }, 10000);
    }

    function termsAccepted() {
      const acceptsTerms = document.getElementById('js-payment-form-terms')
      if (!acceptsTerms.checked) {
        document.getElementById('js-payment-form-terms-error').classList.remove('u-hidden')
      } else {
        document.getElementById('js-payment-form-terms-error').classList.add('u-hidden')
      }
      return acceptsTerms.checked
    }

    paymentForm.addEventListener('submit', async (event) => {
      event.preventDefault();

      if (!termsAccepted()) return;

      // stripe flow
      const {error} = await stripe.confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url: paymentForm.dataset.returnUrl,
        },
      });

      if (error) {
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Show error to your customer (for example, payment
        // details incomplete)
        console.log(error.message);
        showMessage(error.message);
      } else {
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
        console.log("SHOULD REDIRECT")
      }
    });

    if (submitButton) {
      submitButton.addEventListener('click', function(e) {
        e.preventDefault();

        if (e.target.textContent === 'Send Payment') {
          var submitEvent = new Event('submit', {
            'bubbles': true,
            'cancelable': true
          });
          paymentForm.dispatchEvent(submitEvent);
        } else {
          invoiceForm.submit();
        }
      })
    }
  }
});
