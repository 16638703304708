import { Controller } from "@hotwired/stimulus"

async function increment(url = '') {
  await fetch(url, { method: 'POST' });
}

export default class extends Controller {
  static values = { url: String }

  count() {
    increment(this.urlValue);
    document.location.href = this.element.href
  }
}
