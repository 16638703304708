var _ = require('underscore');

document.addEventListener('turbo:load', function() {
  var colleagues = new Array();

  $(".colleague-link").on('click', function(event) {
    event.preventDefault();
    contacts(Number(this.id));
    toggleDrawer();
  });

  $("#message-colleagues").on('click', function(event) {
    addQuery();
    colleagues = new Array();
  });

  $("#cancel-colleagues").on('click', function(event) {
    event.preventDefault();
    let collies = Array.from(colleagues);
    _.each(collies, function(colleague) {
      contacts(colleague);
    });
    setText();
    toggleDrawer();
  });

  function contacts(contact) {
    if (includes(colleagues, contact)) {
      const index = colleagues.indexOf(contact);
      if (index > -1) {
        colleagues.splice(index, 1);
      }
      markUnSend(contact);
    } else {
      colleagues.push(contact);
      markSend(contact);
    }
    setText();
  }

  function setText() {
    const counter = document.getElementById("colleagues-to-message");
    counter.innerHTML = colleagues.length;
  }

  function toggleDrawer() {
    if (colleagues.length == 0) {
      $(".colleague-messaging-tray").fadeOut();
    } else if (colleagues.length > 0) {
      $(".colleague-messaging-tray").fadeIn();
    }
  }

  function markSend(contact) {
    element = "colleague_" + contact;
    document.getElementById(element).style.backgroundColor = "#29690A";
  }

  function markUnSend(contact) {
    element = "colleague_" + contact;
    document.getElementById(element).style.backgroundColor = "#FCF9EF";
  }

  function addQuery() {
    let query = "?colleague_ids=" + colleagues.toString();
    $("#message-colleagues").attr(
      "href",
      $("#message-colleagues").attr("href") + query
    );
  }

  function GetIEVersion() {
    var sAgent = window.navigator.userAgent;
    var Idx = sAgent.indexOf("MSIE");

    // If IE, return version number.
    if (Idx > 0)
      return parseInt(sAgent.substring(Idx + 5, sAgent.indexOf(".", Idx)));
    // If IE 11 then look for Updated user agent string.
    else if (!!navigator.userAgent.match(/Trident\/7\./)) return 11;
    else return 0; //It is not IE
  }

  function includes(container, value) {
    var returnValue = false;
    var pos = container.indexOf(value);
    if (pos >= 0) {
      returnValue = true;
    }
    return returnValue;
  }

  function doesNotInclude(container, value) {
    var returnValue = false;
    var pos = container.indexOf(value);
    if (pos < 0) {
      returnValue = true;
    }
    return returnValue;
  }

  // Polyfill

  if (!Array.from) {
    Array.from = (function() {
      var toStr = Object.prototype.toString;
      var isCallable = function(fn) {
        return (
          typeof fn === "function" ||
          toStr.call(fn) === "[object Function]"
        );
      };
      var toInteger = function(value) {
        var number = Number(value);
        if (isNaN(number)) {
          return 0;
        }
        if (number === 0 || !isFinite(number)) {
          return number;
        }
        return (number > 0 ? 1 : -1) * Math.floor(Math.abs(number));
      };
      var maxSafeInteger = Math.pow(2, 53) - 1;
      var toLength = function(value) {
        var len = toInteger(value);
        return Math.min(Math.max(len, 0), maxSafeInteger);
      };

      // The length property of the from method is 1.
      return function from(arrayLike /*, mapFn, thisArg */) {
        // 1. Let C be the this value.
        var C = this;

        // 2. Let items be ToObject(arrayLike).
        var items = Object(arrayLike);

        // 3. ReturnIfAbrupt(items).
        if (arrayLike == null) {
          throw new TypeError(
            "Array.from requires an array-like object - not null or undefined"
          );
        }

        // 4. If mapfn is undefined, then let mapping be false.
        var mapFn = arguments.length > 1 ? arguments[1] : void undefined;
        var T;
        if (typeof mapFn !== "undefined") {
          // 5. else
          // 5. a If IsCallable(mapfn) is false, throw a TypeError exception.
          if (!isCallable(mapFn)) {
            throw new TypeError(
              "Array.from: when provided, the second argument must be a function"
            );
          }

          // 5. b. If thisArg was supplied, let T be thisArg; else let T be undefined.
          if (arguments.length > 2) {
            T = arguments[2];
          }
        }

        // 10. Let lenValue be Get(items, "length").
        // 11. Let len be ToLength(lenValue).
        var len = toLength(items.length);

        // 13. If IsConstructor(C) is true, then
        // 13. a. Let A be the result of calling the [[Construct]] internal method of C with an argument list containing the single item len.
        // 14. a. Else, Let A be ArrayCreate(len).
        var A = isCallable(C) ? Object(new C(len)) : new Array(len);

        // 16. Let k be 0.
        var k = 0;
        // 17. Repeat, while k < len… (also steps a - h)
        var kValue;
        while (k < len) {
          kValue = items[k];
          if (mapFn) {
            A[k] =
              typeof T === "undefined"
                ? mapFn(kValue, k)
                : mapFn.call(T, kValue, k);
          } else {
            A[k] = kValue;
          }
          k += 1;
        }
        // 18. Let putStatus be Put(A, "length", len, true).
        A.length = len;
        // 20. Return A.
        return A;
      };
    })();
  }
});
